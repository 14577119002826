<template>
  <div>
    <!-- 我的积分轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <img :src="item.url" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 积分兑换 -->
    <div class="main">
      <div class="exchange">
        <div class="title">积分换话费</div>
        <ul class="integral_list">
          <li
            v-for="(v, index) in productList"
            :key="index"
            :class="{ active: index == active }"
            @click="
              active = index;
              show = true;
            "
          >
            <h5>{{ v.title }}</h5>
            <p>({{ v.points }}积分)</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 询问框 -->
    <Popup :show="show" @showClick="closeClick(show)">
      <div slot="conter" class="conter" v-if="active != null">
        <p>您确定使用{{ productList[active].points }}积分</p>
        <p>兑换{{ productList[active].title }}吗？</p>
      </div>
      <div slot="bottom" class="bottom" v-if="member.mobilePhoneInfo">
        （当前可用积分：{{ member.mobilePhoneInfo.pointInfo.currentPoints  }}分）
      </div>
      <div slot="btn" class="btn">
        <p class="back" @click="show = false">取消</p>
        <p class="pay" @click="toExchange">确定</p>
      </div>
    </Popup>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import topHead from "../../../components/topHead.vue";
import Popup from "../../../components/Popup.vue";
import { Toast } from "vant";
export default {
  name: "integral",
  data() {
    return {
      show: false,
      active: null,
    };
  },
  created() {
    this.$nextTick(() => {
      this.$store.dispatch("pointsExchange/getBanner");
      this.$store.dispatch("pointsExchange/getExchangeProductList");
      this.$store.dispatch("getMember");
    });
  },
  computed: {
    ...mapState("pointsExchange", ["bannerList", "productList", "userPoints"]),
    ...mapState(['member'])
  },
  components: {
    topHead,
    Popup,
  },
  methods: {
    closeClick(show) {
      this.show = !show;
    },
    toExchange() {
      if (
        this.productList[this.active].points > this.member.mobilePhoneInfo.pointInfo.currentPoints 
      ) {
        this.show = false;

        this.$router.push({ path: "/exchangeFail" });
        return;
      }
      this.$store.dispatch("pointsExchange/exchange", this.productList[this.active]).then(() => {
        this.$router.push({ path: "/exchangeSuccess" });
      });
     
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0 px2rem(30);
}
// 轮播图
.my-swipe {
  position: relative;
  left: 0;
  width: 100vw;
  height: px2rem(350);
  border-radius: px2rem(20);
  margin-top: px2rem(20);
  margin-bottom: px2rem(40);
  text-align: center;
}
.van-swipe-item img {
  width: px2rem(690);
  height: px2rem(290);
  color: #fff;
  border-radius: px2rem(20);
  box-shadow: 0 px2rem(5) px2rem(20) rgba($color: #000000, $alpha: 0.3);
  margin-top: px2rem(20);
}
.title {
  font-weight: bold;
  font-size: px2rem(30);
  color: #333333;
}
// 弹窗
.conter {
  text-align: center;
  color: #333333;
  font-size: px2rem(30);
  font-weight: bold;
  line-height: px2rem(58);
  // border-bottom: 1px solid #dfe0e7;
}
.bottom {
  line-height: px2rem(80);
  font-size: px2rem(24);
  color: #909198;
  text-align: center;
}
.btn {
  display: flex;
  justify-content: space-around;
}
.btn p {
  width: px2rem(210);
  height: px2rem(70);
  line-height: px2rem(70);
  font-size: px2rem(28);
  font-weight: bold;
  color: #336289;
  box-shadow: 0 px2rem(5) px2rem(10) rgba($color: #000000, $alpha: 0.2);
  border-radius: px2rem(30);
  text-align: center;
  background-color: #bdcbd0;
}
.btn .pay {
  background-color: #336289;
  color: #fff;
}
//列表
.integral_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: px2rem(15);
}
.integral_list li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: px2rem(212);
  height: px2rem(84);
  font-size: px2rem(26);
  border: 2px solid #abacb1;
  border-radius: px2rem(9);
  margin-top: px2rem(20);
  color: #5d5f64;
  font-weight: 600;
}
.integral_list h5 {
  font-size: px2rem(26);
  color: #5d5f64;
}
.integral_list p {
  font-size: px2rem(24);
  color: #999999;
  margin-top: px2rem(-20);
}
.integral_list .active {
  background-color: #336289;
  box-shadow: 0 px2rem(5) px2rem(15) rgba($color: #000000, $alpha: 0.2);
  border: none;
}
.active h5,
.active p {
  color: #fff !important;
}
</style>